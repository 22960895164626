import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import AuthApi from '@lib/api/auth';
import isEmpty from '@utils/isEmpty';
import { useRouter } from 'next/router';
import { useData } from './product';

export interface AuthContextData {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  setLogin: (token: string) => void;
  setLogout: () => void;
}

export const authContextDefaultValue: AuthContextData = {
  isAuthenticated: false,
  user: null,
  loading: true,
  setLogin: () => null,
  setLogout: () => null,
};

export const AuthStateContext = createContext<AuthContextData>(
  authContextDefaultValue
);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const accessToken = Cookies.get('usukh_token');
  useEffect(() => {
    async function loadUserFromCookies() {
      try {
        if (accessToken) {
          const { data } = await new AuthApi().getCurrentUser(accessToken);
          if (data && data?.status_code === 200) {
            if (!isEmpty(data?.result)) {
              setUser(data?.result);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, [router.asPath]);

  const setLogin = async token => {
    try {
      if (token) {
        const { data: result } = await new AuthApi().getCurrentUser(token);
        if (result && result?.status_code === 200) {
          setUser(result?.result);
        }
      }
    } catch (err) {
      console.log('Login hiihed aldaa garlaa');
    }
  };

  const setLogout = () => {
    Cookies.remove('usukh_token');
    setUser(null);
  };
  return (
    <AuthStateContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        loading,
        setLogin,
        setLogout,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
