import React, { createContext, useState, useContext, ReactNode } from 'react';

interface Data {
  [key: string]: any;
}

interface DataContextProps {
  storage: Data;
  setValues: (values: Data) => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [storage, setStorage] = useState<Data>({});

  const setValues = (values: Data) => {
    setStorage(prevData => ({
      ...prevData,
      ...values,
    }));
  };

  return (
    <DataContext.Provider value={{ storage, setValues }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): DataContextProps => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
