import React, { Component } from 'react';
class Loader extends Component {
  render() {
    return (
      <div className="backdrop-blur bg-gray-800/60 web-loader z-50">
        <div className="inline-block h-24 w-24 animate-spin rounded-full border-[2.5px] border-r-transparent">
          <span className="absolute hidden">Loading...</span>
        </div>
        <div className="flex justify-center absolute animate-pulse">
          <img src="/assets/white_logo.png" alt="" className="h-12" />
        </div>
      </div>
    );
  }
}

export default Loader;
